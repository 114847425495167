package tripper.validation

object Restrictions {
  object Comments {
    const val maxLength = 500
  }
  object Images {
    const val maxSize = 20L * 1024 * 1024
  }
  object Files {
    const val maxSize = 100L * 1024 * 1024
  }
  object SearchQuery {
    const val minLength = 4
    const val maxLength = 50
  }
  object Requests {
    const val maxItems = 100
  }
  object Trips {
    const val titleMaxLength = 80
    const val summaryMaxLength = 500
    const val wayPointsMaxNumber = 20
  }
  object WayPoints {
    const val imagesMaxNumber = 10
    const val nameMaxLength = 80
    const val locationMaxLength = 200
  }
  object Users {
    const val firstNameMaxLength = 50 
    const val lastNameMaxLength = 50
    const val nickameMaxLength = 50
    const val aboutMaxLength = 500
  }
}