package tripper.search

import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.modules.polymorphic
import kotlinx.serialization.modules.subclass
import tripper.trips.Trip
import tripper.users.User
import kotlin.reflect.KClass

interface SearchResult {
  val searchKey: Key<out SearchResult>
  
  data class Key<T: SearchResult>(val clazz: KClass<T>, val id: String)
}

val searchResultModule = SerializersModule {
  polymorphic(SearchResult::class) {
    subclass(Trip::class)
    subclass(User::class)
  }
}