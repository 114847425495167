package tripper.likes

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import tripper.comments.ContentId
import tripper.users.UserRef

@Serializable
data class Like(val contentId: ContentId, val userId: UserRef.Id, @SerialName("_key") val rawId: String? = null) {
  val id get() = checkNotNull(rawId) { "Like not stored" }
}
