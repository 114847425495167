package tripper

import kotlinx.serialization.json.JsonBuilder
import tripper.search.searchResultModule

fun JsonBuilder.jsonConfig() { 
  explicitNulls = false
  ignoreUnknownKeys = true
  encodeDefaults = true
  serializersModule = searchResultModule
}