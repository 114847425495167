package tripper.trips

import kotlinx.datetime.Instant
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import tripper.comments.ContentId
import tripper.comments.ContentId.Type.TRIP
import tripper.domain.WayPoint
import tripper.dto.Access
import tripper.dto.Access.PUBLIC
import tripper.search.SearchResult
import tripper.users.UserRef

@Serializable
@SerialName("trip")
data class Trip(
  @SerialName("_key")
  val rawId: String? = null,
  val title: String = "",
  val summary: String? = null,
  val access: Access = PUBLIC,
  val wayPoints: List<WayPoint> = emptyList(),
  val authorId: UserRef.Id,
  @SerialName("_rev")
  val revision: String? = null,
  val createdAt: Instant? = null,
): SearchResult {
  val id get() = requireNotNull(rawId) { "Trip not stored" }
  override val searchKey get() = SearchResult.Key(this::class, id)
}

val Trip.contentId get() = ContentId(id, TRIP)