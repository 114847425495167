package tripper.users

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import tripper.InternalException
import tripper.auth.dto.Provider
import tripper.domain.Email
import tripper.domain.Url
import tripper.search.SearchResult
import tripper.users.UserRef.Id
import tripper.users.UserRef.Nickname
import kotlin.jvm.JvmInline

interface BaseUser {
  val rawId: Id?
  val avatarUrl: Url?
  val firstName: String
  val lastName: String
  val nickname: Nickname?
  val revision: String?
  val about: String?

  val id get() = rawId ?: throw InternalException("User not stored")
}

@Serializable
@SerialName("user")
data class User(
  @SerialName("_key")
  override val rawId: Id? = null,
  override val avatarUrl: Url? = null,
  override val firstName: String,
  override val lastName: String,
  override val nickname: Nickname? = null,
  @SerialName("_rev")
  override val revision: String? = null,
  override val about: String? = null,
): BaseUser, SearchResult {
  override val searchKey get() = SearchResult.Key(this::class, id.value)
}

@Serializable
data class FullUser(
  val provider: Provider,
  override val avatarUrl: Url? = null,
  val email: Email? = null,
  override val firstName: String,
  override val lastName: String,
  override val nickname: Nickname?,
  @SerialName("_key")
  override val rawId: Id? = null,
  @SerialName("_rev")
  override val revision: String? = null,
  override val about: String? = null,
): BaseUser

val BaseUser.ref get() = nickname ?: id

sealed interface UserRef {
  @Serializable
  @JvmInline
  value class Id(val value: String): UserRef {
    override fun toString() = value
  }
  @Serializable
  @JvmInline
  value class Nickname(val value: String): UserRef {
    override fun toString() = value
  }
}