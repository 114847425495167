package tripper

import kotlinx.datetime.DateTimePeriod
import tripper.dto.Access
import tripper.dto.Access.PRIVATE
import tripper.dto.Access.PUBLIC
import tripper.dto.Language
import tripper.dto.Language.EN
import tripper.dto.Language.RU

//todo split to files per-language
class Messages(private val language: Language) {

  fun newTrip(): String {
    return when(language) {
      RU -> "Новый маршрут"
      EN -> "New trip"
    }
  }
  
  fun editTrip(): String {
    return when(language) {
      RU -> "Редактирование маршрута"
      EN -> "Edit trip"
    }
  }

  fun languageOption(language: Language): String = when (language) {
    RU -> "Русский"
    EN -> "English"
  }

  fun title() = when(language) {
    RU -> "Заголовок"
    EN -> "Title"
  }

  fun access() = when(language) {
    RU -> "Доступ"
    EN -> "Access"
  }

  fun accessOption(access: Access): String {
    return when(language) {
      RU -> when(access) {
        PUBLIC -> "Публичный"
        PRIVATE -> "Приватный"
      }
      EN -> when(access) {
        PUBLIC -> "Public"
        PRIVATE -> "Private"
      }
    }
  }

  fun logout() = when(language) {
    RU -> "Выйти"
    EN -> "Logout"
  }

  fun startDate() = when(language) {
    RU -> "Дата начала"
    EN -> "Start date"
  }

  fun endDate() = when(language) {
    RU -> "Дата окончания"
    EN -> "End date"
  }

  fun describeYourAdventures() = when(language) {
    RU -> "Опишите ваши приключения..."
    EN -> "Describe your adventures..."
  }

  fun save() = when(language) {
    RU -> "Сохранить"
    EN -> "Save"
  }

  fun edit() = when(language) {
    RU -> "Редактировать"
    EN -> "Edit"
  }

  fun pageNotFound() = when(language) {
    RU -> "Страница не найдена"
    EN -> "Page not found"
  }

  fun somethingWentWrongError() = when(language) {
    RU -> "Что-то пошло не так"
    EN -> "Something went wrong"
  }

  fun period(period: DateTimePeriod) = when(language) {
    RU -> period(
      period,
      years = { period.years.chooseFormRu("год", "года", "лет") },
      months = { period.months.chooseFormRu("месяц", "месяца", "месяцев") },
      chooseFormDays = { it.chooseFormRu("день", "дня", "дней") },
    )
    EN -> period(
      period,
      years = { period.years.chooseFormEn("year") },
      months = { period.months.chooseFormEn("month") },
      chooseFormDays = { it.chooseFormEn("day") },
    )
  }
  
  private fun period(period: DateTimePeriod, years: () -> String, months: () -> String, chooseFormDays: (Int) -> String) = buildList {
    if (period.years > 0) add("${period.years} ${years()}")
    if (period.months > 0) add("${period.months} ${months()}")
    if (period.days > 0 || isEmpty()) {
      val days = period.days.coerceAtLeast(1)
      add("$days ${chooseFormDays(days)}")
    }
  }.joinToString(" ")

  fun places(number: Int) = when(language) {
    RU -> "$number ${number.chooseFormRu("место", "места", "мест")}"
    EN -> "$number ${number.chooseFormEn("place")}"
  }

  fun noTrips() = when(language) {
    RU -> "Нет маршрутов"
    EN -> "No trips"
  }

  fun profile() = when(language) {
    RU -> "Профиль"
    EN -> "Profile"
  }

  fun trips() = when(language) {
    RU -> "Маршруты"
    EN -> "Trips"
  }

  fun trip() = when(language) {
    RU -> "Маршрут"
    EN -> "Trip"
  }

  fun createTrip() = when(language) {
    RU -> "Создать маршрут"
    EN -> "Create trip"
  }

  fun firstName() = when(language) {
    RU -> "Имя"
    EN -> "First name"
  }

  fun lastName() = when(language) {
    RU -> "Фамилия"
    EN -> "Last name"
  }

  fun nickname() = when(language) {
    RU -> "Ник"
    EN -> "Nickname"
  }

  fun nicknameTaken() = when(language) {
    RU -> "Ник занят другим пользователем"
    EN -> "Nickname is taken by another user"
  }

  fun search() = when(language) {
    RU -> "Поиск"
    EN -> "Search"
  }

  fun inputMoreThan(size: Int) = when(language) {
    RU -> "Введите более $size символов"
    EN -> "Input more than $size symbols"
  }
  
  fun inputComment() = when(language) {
    RU -> "Введите комментарий"
    EN -> "Input comment"
  }

  fun tooLongComment() = when(language) {
    RU -> "Слишком длинный комментарий"
    EN -> "Too long comment"
  }

  fun addComment() = when(language) {
    RU -> "Добавить комментарий..."
    EN -> "Add a comment..."
  }
  
  fun send() = when(language) {
    RU -> "Отправить"
    EN -> "Send"
  }

  fun comments() = when(language) {
    RU -> "Комментарии"
    EN -> "Comments"
  }

  fun noComments() = when(language) {
    RU -> "Пока нет ни одного комментария"
    EN -> "There is no comments"
  }

  fun follow() = when(language) {
    RU -> "Подписаться"
    EN -> "Follow"
  }

  fun unfollow() = when(language) {
    RU -> "Отписаться"
    EN -> "Unfollow"
  }

  fun followings() = when(language) {
    RU -> "Подписки"
    EN -> "Subscriptions"
  }

  fun favorites() = when(language) {
    RU -> "Избранное"
    EN -> "Favorites"
  }

  fun addWayPoint() = when(language) {
    RU -> "Добавить место"
    EN -> "Add waypoint"
  }

  fun placeName() = when(language) {
    RU -> "Название места"
    EN -> "Place name"
  }
  
  fun maxImageSize(maxSize: Long) = when(language) {
    RU -> "Максимальный размер изображения ${maxSize / 1024 / 1024}Мб"
    EN -> "Max image size ${maxSize / 1024 / 1024}Mb"
  }

  fun maxFileSize(maxSize: Long) = when(language) {
    RU -> "Максимальный размер файла ${maxSize / 1024 / 1024}Мб"
    EN -> "Max file size ${maxSize / 1024 / 1024}Mb"
  }

  fun notAuthorized() = when(language) {
    RU -> "Не авторизован"
    EN -> "Not authorized"
  }
  
  fun noAccess() = when(language) {
    RU -> "Нет доступа"
    EN -> "No access"
  }

  fun resourceNotFound() = when(language) {
    RU -> "Ресурс не найден"
    EN -> "Resource not found"
  }

  fun deleteTrip() = when(language) {
    RU -> "Удалить маршрут"
    EN -> "Delete trip"
  }

  fun deleteWayPoint() = when(language) {
    RU -> "Удалить место"
    EN -> "Delete waypoint"
  }

  fun noSearchResults() = when(language) {
    RU -> "По вашему запросу ничего не найдено"
    EN -> "No results were found"
  }

  fun loadMoreComments() = when(language) {
    RU -> "Загрузить ещё комментарии"
    EN -> "Load more comments"
  }
  
  fun preview() = when(language) {
    RU -> "Предпросмотр"
    EN -> "Preview"
  }

  fun previewTrip() = when(language) {
    RU -> "Предпросмотр маршрута"
    EN -> "Preview trip"
  }
  
  fun uploadAvatar() = when(language) {
    RU -> "Загрузить аватар"
    EN -> "Upload avatar"
  }

  fun uploadImage() = when(language) {
    RU -> "Загрузить изображение"
    EN -> "Upload image"
  }

  fun inputTitle() = when(language) {
    RU -> "Введите заголовок"
    EN -> "Input title"
  }

  fun maxTitle(max: Int) = when(language) {
    RU -> "Максимальная длина заголовка $max"
    EN -> "Max title size $max"
  }

  fun maxSummary(max: Int) = when(language) {
    RU -> "Максимальная длина описания $max"
    EN -> "Max summary size $max"
  }

  fun summary() = when(language) {
    RU -> "Краткое описание"
    EN -> "Summary"
  }
  
  fun wayPointRequired() = when(language) {
    RU -> "Добавьте хотя бы одну точку маршрута"
    EN -> "Add at least one waypoint"
  }

  fun maxWayPoints(max: Int) = when(language) {
    RU -> "Максимум $max точек маршрута"
    EN -> "Maximum $max waypoints"
  }

  fun inputWayPointName() = when(language) {
    RU -> "Введите название точки маршрута"
    EN -> "Input waypoint name"
  }
  
  fun maxWayPointName(max: Int) = when(language) {
    RU -> "Максимальная длина названия точки маршрута $max"
    EN -> "Max waypoint name size $max"
  }

  fun specifySince() = when(language) {
    RU -> "Укажите дату начала"
    EN -> "Specify a start date"
  }

  fun specifyUntil() = when(language) {
    RU -> "Укажите дату окончания"
    EN -> "Specify a end date"
  }

  fun sinceMoreThanUntil() = when(language) {
    RU -> "Дата начала позже даты окончания"
    EN -> "Start date more than end date"
  }

  fun untilLessThanSince() = when(language) {
    RU -> "Дата окончания раньше даты начала"
    EN -> "End date less than start date"
  }
  
  fun maxImages(max: Int) = when(language) {
    RU -> "Максимум $max изображений"
    EN -> "Maximum $max images"
  }

  fun specifyLocation() = when(language) {
    RU -> "Укажите расположение на карте"
    EN -> "Specify location on map"
  }

  fun specifyOnMap() = when(language) {
    RU -> "Укажите на карте"
    EN -> "Specify on map"
  }
  
  fun inputLocation() = when(language) {
    RU -> "Введите расположение"
    EN -> "Input location"
  }

  fun maxWayPointLocation(max: Int) = when(language) {
    RU -> "Максимальная длина расположения $max"
    EN -> "Max location size $max"
  }

  fun location() = when(language) {
    RU -> "Расположение"
    EN -> "Location"
  }

  fun inputFirstName() = when(language) {
    RU -> "Введите имя"
    EN -> "Input first name"
  }

  fun inputLastName() = when(language) {
    RU -> "Введите фамилию"
    EN -> "Input last name"
  }

  fun maxFirstName(max: Int) = when(language) {
    RU -> "Максимальная длина имени $max"
    EN -> "Max first name $max"
  }

  fun maxLastName(max: Int) = when(language) {
    RU -> "Максимальная длина фамилии $max"
    EN -> "Max last name $max"
  }

  fun maxNickname(max: Int) = when(language) {
    RU -> "Максимальная длина ника $max"
    EN -> "Max nickname $max"
  }

  fun invalidNicknameFormat() = when(language) {
    RU -> "Допустимые символы: a-zA-Z0-9_"
    EN -> "Valid symbols: a-zA-Z0-9_"
  }

  fun maxAbout(max: Int) = when(language) {
    RU -> "Максимальная длина описания $max"
    EN -> "Max description $max"
  }
  
  fun about() = when(language) {
    RU -> "О себе"
    EN -> "About"
  }

  fun tipTrip() = "TipTrip"
  
  fun title(subtitle: String) = "Tip-Trip | $subtitle"
  
  fun home() = when(language) {
    RU -> "Главная"
    EN -> "Home"
  }

  fun requestLimitExceeded() = when(language) {
    RU -> "Превышен лимит запросов. Повторите запрос позже"
    EN -> "Request limit exceeded. Please try again later"
  }

  fun tooLongRequest() = when(language) {
    RU -> "Слишком долгий запрос. Повторите запрос позже"
    EN -> "Too long request. Please try again later"
  }

  fun Int.chooseFormRu(singular: String, endsWithTwoThreeFour: String, endsWithOther: String): String {
    if (this % 100 == 11 || this % 100 == 12 || this % 100 == 13 || this % 100 == 14) return endsWithOther
    if (this % 10 == 1) return singular
    if (this % 10 == 2 || this % 10 == 3 || this % 10 == 4) return endsWithTwoThreeFour 
    return endsWithOther
  }

  fun Int.chooseFormEn(singular: String): String {
    return if (this == 1) singular else "${singular}s"
  }
}
