package tripper.followings

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import tripper.users.UserRef

@Serializable
data class Following(
  val authorId: UserRef.Id,
  val followerId: UserRef.Id,
  @SerialName("_key") val rawId: String? = null,
) {
  val id get() = checkNotNull(rawId) { "Following not stored" }
}
