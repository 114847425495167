package tripper.domain

import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class WayPoint(
  val location: Location? = null,
  val name: String = "",
  val since: LocalDateTime? = null,
  val until: LocalDateTime? = null,
  val description: String = "",
  val images: List<FileMeta> = emptyList(),
)

fun WayPoint.location(): Location = requireNotNull(location) { "Location not provided" }